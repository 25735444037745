import React from "react";
import './MeetTheTeamSection.css';
import TeamMember from "./TeamMember";

export function MeetTheTeamSection() {
    return (
        <>
            <div className="meet-the-team-section">
                <h1>Meet The Team</h1>
                <div className="team">
                    <TeamMember
                        Name='Luke Huggett'
                        Title='Director and Founder'
                        Image='.\img\luke-profile-512.png'>
                        <p>Luke has more than a decade of experience in the IT and computer science industry, working across the Healthcare, Research, Minerals and Energy sectors.</p>
                        <p>He is a self-driven and highly motivated individual with a wide, diverse range of skills with deep expertise in specific areas.</p>
                        <p>Notable achievements of his career so far are:</p>
                        <ul>
                            <li>Lead developer for a first of class Digital Twin VR Viewing Platform.</li>
                            <li>Managed and delivered over 10+ large scale commercial projects.</li>
                            <li>Lead teams of up to 14 across multiple timezones, providing Class A uptime support</li>
                        </ul>
                        <p>He has worked with multiple clients and employers both from large multinational enterprise companies as well as small start-up operations.</p>
                    </TeamMember>
                </div>
            </div>
        </>
    );

}

export default MeetTheTeamSection;