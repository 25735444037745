import React from 'react';
import '../../App.css';
import AboutSection from '../AboutSection'
import MeetTheTeamSection from '../MeetTheTeamSection'
import CompanyValues from '../CompanyValues'
import Footer from '../Footer';

export default function About() {
  return (
  <>
    <AboutSection />
    <CompanyValues Value1='Integrity' Value2='Collaboration' Value3='Engagement' Value4='Long Term Value' />
    <MeetTheTeamSection />
    <Footer />
  </>
  );
}
