import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Footer from '../Footer';

function Home() {
  return (
    <>
      <HeroSection src="./vid/bushfire-chopper.mp4" autoplay loop muted />
      <Cards />
      <Footer />
    </>
  );
}

export default Home;
