import React from "react";
import './CompanyValues.css';

export function CompanyValues(props) {
    return (
        <>
            <div className='company-values'>
                <h1>Our company values</h1>
                <div className='company-value-group'>
                    <h1 className='company-value'>{props.Value1}</h1>
                    <h1 className='company-value'>{props.Value2}</h1>
                    <h1 className='company-value'>{props.Value3}</h1>
                    <h1 className='company-value'>{props.Value4}</h1>
                </div>
            </div>
            <img className='company-values-background' src='./img/values-background.png' />
        </>
    );
};

export default CompanyValues;