import React from 'react';
import '../../App.css';
import ServicesSection from '../ServicesSection';
import Footer from '../Footer';

export default function Services() {
  return (
    <>
      <ServicesSection />
      <Footer />
    </>
  )
}
