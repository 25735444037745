import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import { VideoBackground } from './VideoBackground';

function HeroSection() {
  return (
    <div className='hero-container'>
      <VideoBackground src='/vid/Yellow-Ace-Background-720p.mp4' id='hero-video' playbackSpeed={0.1} autoPlay={true} loop={true} muted={true} />
      <h1>THE <em>ACE</em> UP YOUR SLEEVE</h1>
      <p>Top-Tier consulting for <em>Software</em> / <em>DevOps</em> / <em>Support</em></p>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          to="./contact"
        >
          CONTACT
        </Button>
      </div>
    </div>

  );
}

export default HeroSection;
