import React from "react";

function setPlaybackSpeed(playbackSpeed, id) {
    var vid = document.querySelector(`#${id}`)
    if (vid != null)
        vid.playbackRate = playbackSpeed;
    return null;
}

export const VideoBackground = ({
    autoPlay = false,
    loop = false,
    muted = false,
    playbackSpeed = 1.0,
    src,
    id}) => {

    return(
        <video id={id} src={src} autoPlay={autoPlay} loop={loop} muted={muted} onWaiting={setPlaybackSpeed(playbackSpeed, id)} onCanPlay={setPlaybackSpeed(playbackSpeed, id)}/>
    );
};