import React from 'react';
import '../../App.css';
import './Portal.css';
import { Form } from 'react-router-dom';
import { Button } from '../Button';
import Footer from '../Footer';

export default function Portal() {
  return (
    <>
      <div className='portal'>
        <h1 className='portal-title'>Client Portal</h1>

        <form>
          <div className='form-row'>
            <label htmlFor='username'>Username</label>
            <input id='username' type='text' />
          </div>
          
          <div className='form-row'>
            <label htmlFor='password'>Password</label>
            <input id='password' type='password' />
          </div>

          <div className='form-row'>
            <Button buttonSize='btn--large' buttonStyle='btn--primary'>
              Login
            </Button>
          </div>
        </form>
      </div>
      <Footer/>
    </>
  );
}
