import React from "react";

function ServiceItem(props) {
    return (
        <>
            <li className='service__item'>
                <h2 className='service__item__title'>{props.Title}</h2>
                <div Id={`${props.Id}_detail`} className="service__detail">
                    <p className="service__detail__text">{props.Text}</p>
                </div>
            </li>
        </>
    );
}

export default ServiceItem;