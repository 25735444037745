import React from 'react';
import ServiceItem from './ServiceItem';
import './ServicesSection.css';

function ServicesSection() {
    return (
        <div className="services">
            <h1>Services Provided</h1>
            <ul className='services-list'>
                <ServiceItem
                    Id='devOps'
                    Title='DevOps Consulting'
                    Text='I have been the driving force of many DevOps teams, including standing up new DevOps departments and bringing fresh innovation to well seasoned teams.'
                    Link=''
                />
                <ServiceItem
                    Id='it'
                    Title='IT Operations and Support'
                    Text='I have broad and deep industry experience with vendor, client and executive engagement, following a standardized ITIL4 approach for incident, problem and change management.'
                    Link=''
                />
                <ServiceItem
                    Id='leadership'
                    Title='Leadership Development'
                    Text='I can help you to improve your employee engagement, retention and productivity. Gain additional tools and practices to improve your execution and connect to a growing leadership network.'
                    Link=''
                />
                <ServiceItem
                    Id='management'
                    Title='Management Consulting'
                    Text='My unique technical background provides an excellent connection with technical teams and powerful insight into the technology space in Australia. I bring an empathetic, accountable and pragmatic management style with me. I can communicate fluently with everyone in and organization from the customers all the way up to executives.'
                    Link=''
                />
                <ServiceItem
                    Id='project'
                    Title='Project Management'
                    Text='Project management where you can see the heartbeat? Absolutely! I have managed projects across multiple timezones and executed critical system migrations with outstanding client confidence.'
                    Link=''
                />
                <ServiceItem
                    Id='dev'
                    Title='Software Development'
                    Text='Code is my passion! I live, breathe, and at times even dream it. From bleeding edge greenfield projects with mixed reality to legacy system enhancement and refactoring projects, I never shy away from a challenge. Every solution I deliver proudly with my name on top of it, a mark of quality.'
                    Link=''
                />
            </ul>
        </div>
    );
}

export default ServicesSection;