import React from 'react';
import './ContactForm.css';

export function ContactForm() {
    return (
        <div className='contact-form-section'>
                <img className='contact-form-background-mask' src='./img/ace-white-mask.png' />
                <h1>Contact</h1>
            <div className='contact-form'>
                <div className='contact-details'>
                    <h2>Get in touch</h2>
                    <p><img className='contact-icon' src='./img/Yellow-Ace-Logo-16.png' /> <a href='https://Yellow-Ace.com'>Yellow Ace Pty Ltd</a></p>
                    <p><i className='contact-icon fa fa-briefcase' /> ABN 69 673 978 946</p>
                    <p><i className='contact-icon fa fa-linkedin' /> <a href='https://linkedin.com/company/yellow-ace'>LinkedIn</a></p>
                    <p><i className='contact-icon fa fa-envelope' /> <a href='mailto:Info@Yellow-Ace.com'>Info@Yellow-Ace.com</a></p>
                    <p><i className='contact-icon fa fa-building' /> Headquarters in Perth, WA</p>
                </div>
                <form>
                    <h3>Start a Conversation</h3>
                    <h5>Under construction! Please Email us for now</h5>
                    <div className='contact-form-row'>
                        <label htmlFor='contact-first-name'>Name</label>
                    </div>
                    <div className='contact-form-row'>
                        <input id='contact-name' type='text' disabled={true} placeholder='Jane Doe'/>
                    </div>
                    <div className='contact-form-row'>
                        <label htmlFor='contact-email'>Email</label>
                    </div>
                    <div className='contact-form-row'>
                        <input id='contact-email' type='email' disabled={true}  placeholder='Jane.Doe@Company.com'/>
                    </div>
                    <div className='contact-form-row'>
                        <label htmlFor='contact-subject-choice'>Subject</label>
                    </div>
                    <div className='contact-form-row'>
                        <select id='contact-subject-choice' disabled={true} >
                            <option value='Service Enquiry'>Service Enquiry</option>
                            <option value='Expression of Interest'>Expression of Interest</option>
                            <option value='Investment Enquiry'>Investment Enquiry</option>
                            <option value='Other'>Other</option>
                        </select>
                    </div>
                    <div className='contact-form-row'>
                        <label htmlFor='contact-body'>Message</label>
                    </div>
                    <div className='contact-form-row'>
                        <textarea id='contact-body' minLength='20' maxLength='8000' disabled={true} placeholder='Got a cool project, need some extra hands or expert guidance? Let us know about it!'/>
                    </div>
                    <input className='btn btn--large' disabled={true} type='submit' value='Under Construction' />
                </form>
            </div>
            <div className='contact-form-background'>
                <img src='./img/perth-background.jpg' />
            </div>
        </div>
    );
};

export default ContactForm;