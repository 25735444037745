import React from "react";
import './AboutSection.css';

function AboutSection() {
    return (
    <div className="about">
        <h1>About Yellow Ace</h1>
        <div className="about-group">
            <div className="about-text">
                <h4>Who is Yellow Ace?</h4>
                <p>Yellow Ace is a start-up founded by Luke Huggett in 2024, the current size of the company is just 1. For larger projects, trusted partnered sub-contractors are engaged. There are plans to expand the team with business as demand grows.</p>
                <h4>What is Yellow Ace Solutions?</h4>
                <p>Yellow Ace Solutions is a boutique IT and Software Consulting Services company. I bring my skills, knowledge and experience to my partnering clients in order to make their visions a reality and overcome their most challenging problems.</p>
                <h4>Why Hire a Consultant?</h4>
                <p>As Australia, and the modern world, becomes more technologically advanced we progress to a highly specialised society. The skills required for companies to compete continue to become more niche to the point where a general skillset, even within a specific role, is not sufficient to execute on increasingly more complex tasks. A demand for specialised skills increases so does the cost, and companies struggle to justify retaining such roles permanently. Hiring consultants fulfills these demands for niche, specialised talent, while only paying for what is required.</p>
                <h4>Why Choose Yellow Ace?</h4>
                <p>Decide on Yellow Ace for a personalised, highly accountable and professional approach. I have a strong focus on value throughout my work with clients, while ensuring reliability, pricision and quality. Join me in building lasting partnerships and projects that stand the test of time. My drive, innovation and creative approach to every solution is not only effective, it's infectious! With adept leadership skills empowering collaboration methodologies, I will inspire more from your teams and show them both that it's possible and how it can be done.</p>
                <h4>Our Mission</h4>
                <p>It has been a long held dream to improve the culture, quality and finesse within the local technology industry in Australia. I aim to increase understanding and adoption of best practices, reducing overall IT budgets while increasing the productivity, impact and staff well-being by doing so.</p>
            </div>
            <div className="about-image-container">
                <img src=".\img\business-meeting.png" alt='Two people having a business meeting, with a laptop and tablet, collaborating together actively.'/>
            </div>  
        </div>
    </div>
    ); 
}

export default AboutSection;

