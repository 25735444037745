import React from 'react';
import './Cards.css';
import { Button } from './Button';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Projects Aced</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='/img/cafm.png'
              text='AMS + CMMS Overhaul'
              label='Project'
              path='/services'
            />
            <CardItem
              src='/img/global-contractors.png'
              text='Global Subcontractor Management System'
              label='DevOps'
              path='/services'
            />
            <CardItem
              src='/img/agility-nco.png'
              text='Major Service Contract Transition Migration'
              label='DevOps'
              path='/services'
            />
            <CardItem
              src='/img/vision.png'
              text='Virtual Reality Digitial Twin Inspection System'
              label='Software'
              path='/services'
            />
            <CardItem
              src='/img/shifty.png'
              text='Nurse Shift-swapping Bounty Board'
              label='Software'
              path='/services'
            />
          </ul>
        </div>
      </div>
      <h1>Complete your team's royal flush</h1>
      <h1>
        <Button
            className='btns btn-large'
            buttonStyle='btn--primary'
            buttonSize='btn--large'
            to="/contact"
            >
            CONTACT
          </Button>
        </h1>
    </div>
  );
}

export default Cards;
