import React from 'react';
import { Link } from 'react-router-dom';


function CardItem(props) {
  return (

    <>
      <li className='cards__item'>
        <Link className='cards__item__link' to={props.path}>
            
            <div className='cards__item__ace-top'><p>A</p><img src="/img/Yellow-Ace-Logo-32.png" alt='a little tiny spade symbol'/></div>
          
            <figure className='cards__item__pic-wrap' data-category={props.label} badge-colour={props.badgeColour}>
            <img
              use-image-width={props.useImageWidth}
              className='cards__item__img'
              alt={props.alt}
              src={props.src}
            />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
          </div>
          <div className='cards__item__ace-bottom'><p>A</p><img src="/img/Yellow-Ace-Logo-32.png" alt='a teensy weensy spade symbol, but it is upside-down'/></div>
        </Link>
      </li>
    </>
  );
}

export default CardItem;
