import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      {/* <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Join the Adventure newsletter to receive our best vacation deals
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div>
      </section> 
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Company</h2>
            <Link to='/endorsements'>Endorsements</Link>
            <Link to='/careers'>Careers</Link>
            <Link to='/about'>About</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Business</h2>
            <Link to='/services'>Services</Link>
            <Link to='/contact'>Contact</Link>
            <Link to='/portal'>Support</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Portfolio</h2>
            <Link to='/'>Projects</Link>
            <Link to='/'>Case Studies</Link>
          </div>
        </div>
      </div>*/}
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/#' className='footer-logo-text'>
              <img className='footer-logo-image' src='/img/Yellow-Ace-Logo-Alt-256.png' alt='Yellow Ace Logo, a spade shape coloured yellow' />
              Yellow Ace Solutions
            </Link>
          </div>
          <small className='website-rights'><p>Yellow Ace © 2024</p><p>ABN 69 673 978 946</p></small>
          <div className='social-icons'>
            
          <Link
              className='social-icon-link linkedin'
              to='https://linkedin.com/company/yellow-ace'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i className='fab fa-linkedin' />
            </Link>
            
            <Link
              className='social-icon-link youtube'
              to='https://www.youtube.com/@Yellow-Ace'
              target='_blank'
              aria-label='YouTube'
            >
              <i className='fab fa-youtube' />
            </Link>

            <Link
              className='social-icon-link twitter'
              to='https://twitter.com/yellow_ace'
              target='_blank'
              aria-label='Twitter'
            >
              <i className='fab fa-x-twitter' />
            </Link>
            <Link
              class='social-icon-link facebook'
              to='https://facebook.com/yellow.ace.solutions'
              target='_blank'
              aria-label='Facebook'
            >
              <i className='fab fa-facebook-f' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
