import React, { Children } from "react";

export function TeamMember(props) {
    return (
        <div className='team-member'>
            <div className="team-member-image-container">
                <img src={props.Image} alt={props.Name} />
            </div>
            <div className='team-member-bio'>
                <h2>{props.Name}</h2>
                <h4>{props.Title}</h4>
                {Children.map(props.children, child =>
                    <div className="bio-item">
                        {child}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TeamMember;